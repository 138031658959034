<template>
    <v-container>
      <v-card class="mx-auto my-5" max-width="1000" style=" color: #333;font-family: 'cairo_reg', sans-serif !important;
   ;    background-color: #fff;">
        <!-- Title Section -->
        <v-card-title class="text-h4 text-center" style="color: #b8860b; font-family: 'cairo_reg', sans-serif !important; font-size: 23px !important;    color: #d4af37;
    font-weight: bold  !important">
          جائزة الساقي للرواية العربية
        </v-card-title>
  
        <!-- Video Section -->
        <v-responsive aspect-ratio="16/9" class="my-4">
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/YOUR_VIDEO_ID" 
            title="Award Video"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </v-responsive>
  
        <!-- Text Section -->
        <v-card-text style="font-family: 'cairo_reg', sans-serif;">
          <v-divider></v-divider>
          <v-card-subtitle class="text-h5" style="color: #b8860b; font-family: 'cairo_reg', sans-serif !important;    font-size: 26px !important;">التعريف:</v-card-subtitle>
          <v-divider></v-divider>
          <p style=" font-size: 24px !important;line-height: normal;    font-family: bbc_nassim, Arial, sans-serif;">
            جائزة الساقي للرواية العربية  هي جائزة سنوية  تطلقها العتبة العباسية المقدسة، لتواكب معطيات الرواية العالمية، مع الأخذ بنظر الاهتمام الاختلاف في الرؤية والرسالة والأهداف، عن الجوائز الأخرى. فلجائزة الساقي معالمها الخاصة، فهي تحتفي بالقيم الإنسانية والأخلاقية التي جاء بها الرسول الأعظم محمد (صلى الله عليه وآله وسلم)، وآله الطاهرين، وتناهض القيّم والمسارات الروائية التي تدعو إلى الانحلال، والابتعاد عن القيم الإنسانية العامة. فهي جائزة تعزز الدور الفاعل للكتابة في الفكر الاجتماعي، و سلوكياته النبيلة والرصينة. وتستدعي الشخصيات الفذة والفاعلة، لتضعها أنموذجاً حياً يعضد السرديات الكبرى للأمة العربية الإسلامية، فضلاً عن سعيها لتغطية المراحل المهمة من تراثنا العربي الإسلامي في العراق خاصة
          </p>
  
          <v-divider></v-divider>
          <v-card-subtitle class="text-h5" style="color: #b8860b;  font-family: 'cairo_reg', sans-serif !important;    font-size: 26px !important;">الرسالة والأهداف:</v-card-subtitle>
          <v-divider></v-divider>
          <p style=" font-size: 24px !important;line-height: normal; font-family: bbc_nassim, Arial, sans-serif;">
            تأسست فكرة هذه الجائزة، بالاستناد إلى توجيهات المتولي الشرعي للعتبة العباسية المقدسة السيد أحمد الصافي "دام عزّه"؛ للعناية بإنتاج "الرواية" فنحن بحاجة كبيرة لهذا النوع الأدبي المهم، فهي ملحمة العصر وهي امتداد لحياتنا، فضلا عن تعزيزها للجانب الثقافي في تحديد معالم المجتمعات وأحوالها، وأزماتها، وآمالها، وطموحاتها، وأوجاعها ومسراتها، وترسيخ رؤيتها للحياة ومنهجها فيه. 
جاءت فكرة تأسيس هذه الجائزة لتشجيع الكتّاب على الكتابة السردية( الروائية) التي تحتفي بتعاليم الدين الإسلامي وقيمه الإنسانية والأخلاقية التي جاء بها النبي محمد (صلى الله عليه وآله وسلم)، وأهل بيته الأطهار( عليهم السلام)، فضلاً عن أهمية ما يجود به هذا الفن من توثيق لما مرت به الأمة الإسلامية من مآسٍ، وهموم في عالمنا القديم والمعاصر. وتهدف الجائزة إلى ترسيخ حضور الروايات المتميزة ذات المنحى الإسلامي عربياً وعالمياً،  فضلا عن الاحتفاء بالروائيين المبدعين العرب وغيرهم ممن يكتبون باللغة العربية وتكريمهم بما يليق بهم، ودعمهم للمضي قدماً نحو آفاق الإبداع والاحتراف والتميز، فضلاً عن تشجيع قراءة الرواية المهمة في تثقيف الأجيال، وترسيخ الوعي الفكري والثقافي والإبداعي، وتأكيد الهوية الحضارية للأمة الإسلامية لديهم، وتعزيز الحوار والتعايش بين أبناء الأمة الإسلامية. 
 وتكون الجائزة بمجال الروايات غير المنشورة (المخطوطة). في موضوع " الحشد الشعبي وفتوى الجهاد المقدسة"

          </p>
  
          <v-divider></v-divider>
          <v-card-subtitle class="text-h5" style="color: #b8860b; font-family: 'cairo_reg', sans-serif !important;    font-size: 26px !important;">شروط وآليات الترشُح:</v-card-subtitle>
          <v-divider></v-divider>
          <v-list dense style=" font-size: 24px !important;line-height: normal; font-family: bbc_nassim, Arial, sans-serif;">
           
   

            <v-list-item>1-أن يكون النص مكتوباً باللغة العربية.</v-list-item>
            <v-list-item>2-أن لا يكون النص منشوراً أو مشاركاً به بمسابقة أخرى.</v-list-item>
            <v-list-item>3-تكون المشاركة بعمل واحد، وفي مجال واحد فقط.</v-list-item>
            <v-list-item>4-يحق للفائزين المشاركة بعد مرور دورتين على فوزهم.</v-list-item>
            <v-list-item> 5-يشترط في مخطوطة الرواية أن لا يقل عدد كلماتها عن (25) ألف كلمة.</v-list-item>
            <v-list-item> 6- التزام الأعمال المشاركة بأخلاقيات الإسلام ومبادئه السامية التي دعا إليها الرسول الأعظم محمد "صلى الله عليه وآله"، وأهل بيته الأطهار"عليهم السلام". </v-list-item>
            <v-list-item> 7-  التزام الأعمال بعدم المساس بأي عرق أو مذهب أو طائفة.</v-list-item>
            <!-- <v-list-item> 
8-
              ترسل الأعمال المشاركة بصيغة PDF إلى:



البريد الإلكتروني:
al-saqi.aaf@alkafeel.net
مرفقاً معه السيرة الذاتية للمشارك ورقم الهاتف.

https://alkafeel.net:2096/
وملء الاستمارة الخاصة بالمشاركة





            </v-list-item> -->
          </v-list>
  
          <p style="font-size: 24px !important; line-height: normal; font-family: bbc_nassim, Arial, sans-serif;">
  ترسل الأعمال المشاركة بصيغة PDF مرفقاً معه السيرة الذاتية للمشارك ورقم الهاتف.  إلى:
</p>
<a  text color="primary" href="mailto:al-saqi.aaf@alkafeel.net" style="font-size: 19px !important; line-height: normal;">
  al-saqi.aaf@alkafeel.net
</a>

          <p style=" font-size: 24px !important;line-height: normal; font-family: bbc_nassim, Arial, sans-serif;">  أو بالضغط على الرابط الآتي وملء الاستمارة الخاصة بالمشاركة </p>


          <a target="_blank"  text color="primary" href="https://docs.google.com/forms/d/e/1FAIpQLSc2C4zasBjO8y3l45hTUmpkQho2BZ4bkkXFZOCXtwEZgfCZZg/viewform" style="font-size: 19px !important; line-height: normal;">
            https://docs.google.com/forms/d/e/1FAIpQLSc2C4zasBjO8y3l45hTUmpkQho2BZ4bkkXFZOCXtwEZgfCZZg/viewform
</a>


  

          <!-- <v-divider></v-divider>
          <v-card-subtitle class="text-h5" style="color: #d4af37;">لجان الجائزة:</v-card-subtitle>
          <v-divider></v-divider>
          <p>تتكون الجائزة من أربع لجان...</p>
   -->

   <p style=" font-size: 24px !important;line-height: normal;">
     <span style="    font-weight: 900; font-family: bbc_nassim, Arial, sans-serif;">ملاحظة</span>: باستطاعة المشاركين أن يستعينوا بالمدونات والوثائق التاريخية المهمة، والشهادات أيضاً لوقائع موسوعة فتوى الدفاع الكفائي التي اضطلعت بتدوينها العتبة العباسية المقدسة في ٨٠ مجلداً.

   </p>
          <v-divider></v-divider>
          <v-card-subtitle class="text-h5" style="color: #b8860b; font-family: 'cairo_reg', sans-serif !important;    font-size: 22px !important;">المكافآت:</v-card-subtitle>
          <v-divider></v-divider>
          <v-list dense style=" font-size: 24px !important;line-height: normal; font-family: bbc_nassim, Arial, sans-serif;">


      

            <v-list-item style=" font-size: 24px !important;line-height: normal; font-family: bbc_nassim, Arial, sans-serif;">المركز الأول: 15 مليون دينار عراقي.</v-list-item>
            <v-list-item style=" font-size: 24px !important;line-height: normal; font-family: bbc_nassim, Arial, sans-serif;">المركز الثاني: 10 مليون دينار عراقي.</v-list-item>
            <v-list-item style=" font-size: 24px !important;line-height: normal; font-family: bbc_nassim, Arial, sans-serif;">المركز الثالث: 7 مليون دينار عراقي.</v-list-item>
            <v-list-item style=" font-size: 24px !important;line-height: normal; font-family: bbc_nassim, Arial, sans-serif;">المركز الرابع:   5   مليون دينار عراقي.</v-list-item>
            <v-list-item style=" font-size: 24px !important;line-height: normal; font-family: bbc_nassim, Arial, sans-serif;">المركز الخامس: 5  مليون دينار عراقي.</v-list-item>

          </v-list>
  
          <p style=" font-size: 24px !important;line-height: normal; font-family: bbc_nassim, Arial, sans-serif;">وتجري التوصية بطباعة الأعمال الفائزة، وتجري ترجمتها لأهم اللغات العالمية والتعاقد مع دور نشر عالمية لغرض نشرها.
أمّا الأعمال الخمسة التي تلي المراكز الخمسة الفائزة، فيجري العمل على طباعتها ونشرها في دور نشر رصينة، مع حصول أصحابها على شهادات تقديرية، وضيافة تكريم.

</p>

<p style=" font-size: 24px !important;line-height: normal; font-family: bbc_nassim, Arial, sans-serif;">جرى الإعلان عن الجائزة في ايلول/ 2024م. ويكون آخر يوم لاستلام المخطوطات المشاركة هو 10/4/ 2025 م. </p>
        </v-card-text>
      </v-card>
    </v-container>
  </template>
  

<script>
export default {
  name: 'AwardDetails',
  methods: {
  sendEmail() {
  
    window.open('mailto:al-saqi.aaf@alkafeel.net');
  }
}

}
</script>
<style scoped>
p {
  margin: 16px 0;
}

.v-card-title, .v-card-subtitle {
  font-family: 'cairo_reg', sans-serif !important;
}

/* Custom colors using golden shades */
.v-card-title {
  color: #b8860b; /* Dark Goldenrod */
}

.v-card-subtitle {
  color: #d4af37; /* Gold */
}

.v-card {
  background-color: #fdf5e6; /* Old Lace */
}

.v-btn {
  color: #b8860b !important; /* Dark Goldenrod */
}
</style>